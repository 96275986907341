import { useHistory } from 'react-router';
import {
  AnyWizardQuestion,
  WizardAnswer,
  WizardInputUploadQuestion,
  WizardMultipleChoiceQuestion,
  WizardTextInputQuestion,
  WizardTreeSelectAutocompleteQuestion,
} from './WizardConfig';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { ListWizard } from './ListWizard/ListWizard';
import { ListWizardConfig } from 'routes/app/lists/wizard/listWizardConfig';
import { useSnacks } from 'hooks/common/useSnacks';
import { Wizard } from './Wizard';
import {
  sendAnalyticsEvent,
  sendPendoEvent,
  SendPosthogEventFnType,
  usePosthog,
} from 'analytics';
import { useState } from 'react';
import { ListEntityType } from 'adapters/types';

export type UseListWizardReturnType = ReturnType<typeof useListWizard>;

export type UseListWizardParams =
  | { config: ListWizardConfig; mode: 'create' }
  | { config: ListWizardConfig; mode: 'edit'; uuid: string };

export function trackWizardAnswerCompletion(
  eventLabel: string,
  wizard: Wizard,
  question: AnyWizardQuestion,
  answer: string,
  sendPosthogEvent: SendPosthogEventFnType
) {
  const params = {
    questionId: question.id,
    wizardId: wizard.config.id,
    wizardVersion: wizard.config.version,
    questionLabel:
      typeof question.label === 'function'
        ? question.label(wizard)
        : question.label,
    questionType: question.type,
    questionSubType: question.subType || '',
    answer,
  };
  sendPendoEvent('List Wizard > Question Answered', params);
  sendAnalyticsEvent(
    'List Wizard',
    'List Wizard > Question Answered',
    undefined,
    undefined,
    params
  );
  sendPosthogEvent('List Wizard > Question Answered', params);
}

export function useListWizard(params: UseListWizardParams) {
  const { config, mode } = params;
  const uuid = 'uuid' in params ? params.uuid : undefined;

  const { sendPosthogEvent } = usePosthog();

  const { showErrorSnack } = useSnacks();
  const history = useHistory();
  const [wizard, setWizard] = useState<ListWizard>(
    new ListWizard(config, mode, uuid)
  );

  const [createListMutation] = prospectsApi.usePutCreateListMutation();
  const [updateWizardParamsMutation] =
    prospectsApi.usePatchUpdateWizardParamsMutation();
  const [patchCrmList] = prospectsApi.usePatchCrmListMutation();

  async function handleUpdate() {
    if (!uuid) throw new Error("Can't update list without uuid.");

    const { is_crm, ...values } = wizard.getFormPayload();

    try {
      // Declare these here so we can avoid closure issues (values not defined in the API calls).
      const updateParams = {
        uuid,
        wizardParams: {
          ...values,
          wizard_state: {
            ...values.wizard_state,
            wizardVersion: wizard.version, // make sure the version is the latest
          },
        },
        averageDealSize: values.average_deal_size,
        listName: values.list_name,
      };

      await updateWizardParamsMutation(updateParams)
        .unwrap()
        .catch((e) => {
          throw e;
        });

      // Toggle CRM for this list
      if (is_crm) {
        await patchCrmList({
          uuid,
          crm_list: is_crm,
        });
      }

      wizard.clearStorage();
      sendPendoEvent('List Wizard > Edit List > Complete', {
        uuid,
        listName: values.list_name,
      });
      sendAnalyticsEvent(
        'List Wizard',
        'List Wizard > Edit List > Complete',
        undefined,
        undefined,
        {
          uuid,
          listName: values.list_name,
        }
      );
      sendPosthogEvent('List Wizard > Edit List > Complete', {
        uuid,
        listName: values.list_name,
      });
      history.push(`/app/myprospects/lists`);
    } catch (error) {
      console.error('Error creating list:', error);
      showErrorSnack('There was an error creating your list.');
    }
  }

  async function handleCreate() {
    const { is_crm, ...values } = wizard.getFormPayload();

    try {
      const createParams = {
        listName: values.list_name,
        icpBuilder: true,
        listType: values.list_type,
        // This field is no longer used by the backend.
        listSource: '',
        listEntityType: values.list_entity_type.toLowerCase() as ListEntityType,
        icpParams: null,
        wizardParams: values,
      };

      const response = await createListMutation(createParams);

      if ('data' in response && 'success' in response.data) {
        if (!response.data.success) {
          showErrorSnack('Error creating list: ' + response.data.message);
          return;
        }
      }

      if (
        'data' in response &&
        'results' in response.data &&
        'uuid' in response.data.results
      ) {
        const { uuid } = response.data.results;

        // Toggle CRM for this list
        if (is_crm) {
          await patchCrmList({
            uuid,
            crm_list: is_crm,
          });
        }

        wizard.clearStorage();
        sendPendoEvent('List Wizard > Create List > Complete', {
          uuid,
          listName: values.list_name,
        });
        sendAnalyticsEvent(
          'List Wizard',
          'List Wizard > Create List > Complete',
          undefined,
          undefined,
          {
            uuid,
            listName: values.list_name,
          }
        );
        sendPosthogEvent('List Wizard > Create List > Complete', {
          uuid,
          listName: values.list_name,
        });
        history.push(`/app/myprospects/lists`);
      } else {
        showErrorSnack('There was an error updating your list.');
        return;
      }
    } catch (error) {
      console.error('Error creating list:', error);
      showErrorSnack('There was an error creating your list.');
      return;
    }
  }

  async function onMultipleChoiceResponse(
    question: WizardMultipleChoiceQuestion,
    answer: WizardAnswer
  ) {
    const value = wizard.questions.valueForMCAnswer(question, answer);
    wizard.storage.saveResponse(question.id, value);
    trackWizardAnswerCompletion(
      'Multiple Choice',
      wizard,
      question,
      value,
      sendPosthogEvent
    );

    // if there are any additional values to save, do so
    if (answer.additionalValues) {
      for (const additionalValue of answer.additionalValues) {
        wizard.storage.saveResponse(
          additionalValue.questionId,
          additionalValue.value
        );

        trackWizardAnswerCompletion(
          'Additional Values',
          wizard,
          question,
          additionalValue.value,
          sendPosthogEvent
        );
      }
    }

    history.push(wizard.questions.urlFor(answer.nextQuestionId));
  }

  async function onInputUploadResponse(
    question: WizardInputUploadQuestion,
    answer: string
  ) {
    try {
      wizard.storage.saveResponse(question.id, answer);

      trackWizardAnswerCompletion(
        'Autocomplete / Upload',
        wizard,
        question,
        answer.length?.toString(),
        sendPosthogEvent
      );

      history.push(wizard.questions.urlFor(question.nextQuestionId as string));
    } catch (e) {
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        sendPosthogEvent('List Wizard > Upload Error > Too Large', {
          questionId: question.id,
          wizardId: wizard.config.id,
          wizardVersion: wizard.config.version,
        });
        showErrorSnack(
          'Your upload is too large for the wizard to handle. Try again with fewer rows.'
        );
      } else {
        console.error('Unexpected error occurred:', e);
      }
    }
  }

  async function onTextInputResponse(
    question: WizardTextInputQuestion,
    answer: string
  ) {
    wizard.storage.saveResponse(question.id, answer);

    trackWizardAnswerCompletion(
      'Text Input',
      wizard,
      question,
      answer,
      sendPosthogEvent
    );

    if (wizard.questionIsFinal(question)) {
      await onFinalQuestionResponse();
      return;
    }

    history.push(wizard.questions.urlFor(question.nextQuestionId as string));
  }

  async function onTreeSelectAutocompleteResponse(
    question: WizardTreeSelectAutocompleteQuestion,
    value: string[]
  ) {
    wizard.storage.saveResponse(question.id, value);
    trackWizardAnswerCompletion(
      'Tree Select Autocomplete',
      wizard,
      question,
      value.length.toString(),
      sendPosthogEvent
    );
    history.push(wizard.questions.urlFor(question.nextQuestionId as string));
  }

  async function onCrmIntegrationResponse(question: AnyWizardQuestion) {
    history.push(wizard.questions.urlFor(question.nextQuestionId as string));
  }

  async function onFinalQuestionResponse() {
    if (mode === 'edit') {
      await handleUpdate();
    } else {
      await handleCreate();
    }
  }

  function onReset() {
    wizard.clearStorage();
    history.push('/app/myprospects/lists');
  }

  // This function is used to mark the wizard as outdated if current version does not match the storage version.
  function markOutdated(outdated: boolean) {
    setWizard((prev) => {
      prev.config.isOutdated = outdated;
      return prev;
    });
  }

  return {
    wizard,
    handleCreate,
    markOutdated,
    callbacks: {
      onMultipleChoiceResponse,
      onInputUploadResponse,
      onTextInputResponse,
      onFinalQuestionResponse,
      onTreeSelectAutocompleteResponse,
      onReset,
      onCrmIntegrationResponse,
    },
  };
}
