import { useHistory } from 'react-router';
import { Wizard } from '../Wizard';
import { WizardConfig, WizardCRMIntegrationQuestion } from '../WizardConfig';
import { Box, Link, Stack, Typography } from '@mui/material';
import { WizardPagination } from '../WizardPagination';
import { useUserState } from 'hooks/common/useUserState';
import Loading from 'components/common/Widgets/Loading';
import { LoadingIcon } from 'components/common/Widgets/LoadingIcon';

export interface CRMIntegrationQuestionProps<TWizard = Wizard<WizardConfig>> {
  wizard: TWizard;
  question: WizardCRMIntegrationQuestion;
  onNextClick: () => void;
}

export function CRMIntegrationQuestion({
  question,
  wizard,
  onNextClick,
}: CRMIntegrationQuestionProps) {
  const history = useHistory();
  const { team, isLoading } = useUserState();

  const crmIsActive = team.crm_connected;

  function onPreviousClick() {
    history.goBack();
  }

  return (
    <Stack spacing={8}>
      <Stack
        flexWrap="wrap"
        alignItems="flex-start"
        key={question.id}
        spacing={2}
      >
        <Typography fontWeight={500} variant="h6">
          {question.label}
        </Typography>
        {question.description ? (
          <Typography variant="body2">{question.description}</Typography>
        ) : null}
        <Box ml={-2} width="100%" sx={{ py: 5 }}>
          {isLoading ? <LoadingIcon /> : null}
          {!isLoading && crmIsActive ? (
            <Typography
              variant="h6"
              sx={{ fontWeight: 400, textAlign: 'center' }}
            >
              🎉 Your CRM is connected, click "Next" to proceed.
            </Typography>
          ) : null}
          {!isLoading && !crmIsActive ? (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" sx={{ fontWeight: 400 }}>
                ⚠️ No CRM is connected.
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 400 }}>
                To create a CRM Pipeline list you must have an active CRM
                integration (HubSpot or Salesforce).
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 400 }}>
                <a href="mailto:support@vendelux.com">Contact us</a> for
                access.
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Stack>
      <WizardPagination
        wizard={wizard}
        question={question}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        canGoForward={crmIsActive}
      />
    </Stack>
  );
}
